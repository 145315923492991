import React from 'react'
import SEO from '../components/seo'
import styles from '../components/backgroundVideo.module.css'
import { Link } from 'gatsby'

export default () => {

    return (
        <div>
            <SEO 
                title='404 error' 
                description='Page is not found'
            />

            <video 
            key='Toilet' 
            poster={require(`../404/404.jpg`)} 
            className={styles.videoClip} 
            autoPlay
            playsInline
            muted 
            loop 
            controlsList='nodownload'
            >
            <source src={require(`../404/404.webm`)} type='video/webm'/>
            <source src={require(`../404/404.mp4`)} type='video/mp4'/>
            Sorry! Your browser does not support video.    
            </video>

            <h1
                style={{
                    width: `100%`,
                    textAlign: `center`,
                    fontSize: `3em`,
                    color: `rgb(255, 255, 255, .85)`,
                    paddingTop: `.1em`
                }}
            >
                404
            </h1>
            <p
                style={{
                    width: `100%`,
                    textAlign: `center`,
                    fontSize: `1.3em`,
                    color: `rgb(255, 255, 255, .85)`,
                    paddingTop: `.2em`
                }}
            >
                Oops!
                <Link
                    style={{
                        textDecoration: `none`,
                        fontSize: `1em`,
                        color: `rgb(150, 255, 150, .85)`,
                        marginLeft: `.1em`,
                        fontWeight: 500,
                    }}
                    to='/'
                > Go Back</Link>
            </p>
        </div>
    )
}

// style={{
//     position: `fixed`,
//     top: `50%`,
//     left: `50%`,
//     transform: `translateX(-50%) translateY(-50%)`,
//     WebkitTransform: `translateX(-50%) translateY(-50%)`,
//     MozTransformStyle: `translateX(-50%) translateY(-50%)`,
//     msTransform: `translateX(-50%) translateY(-50%)`,
//     OTransform: `translateX(-50%) translateY(-50%)`,

//     minWidth: `100%`,
//     minHeight: `100%`,
//     width: `auto`,
//     height: `auto`,
//     zIndex: -1000,
//     overflow: `hidden`,

//     backgroundSize: `cover`,
// }}